<template>
    <v-card>
        <v-card-text>
            <v-data-table
                :headers="headers"
                :items="profiles"
                sort-by="name"
                :items-per-page="20"
                :footer-props="{
                    'items-per-page-options': [10, 20, 50, 100, 200],
                    'show-current-page': true,
                    'show-first-last-page': true,
                }"
            >
                <template v-slot:top>
                    <v-toolbar flat color="transparent">
                        <v-spacer></v-spacer>
                        <v-dialog v-model="dialogCreate" max-width="800px" persistent>
                            <template v-slot:activator="{ on }">
                                <v-btn rounded color="primary" dark v-on="on">
                                    {{ $t('buttons.createProfile') }}
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-title>
                                    <span class="headline blue--text text--darken-1">{{ $t('title.newProfile') }}</span>
                                </v-card-title>

                                <v-card-text>
                                    <v-text-field
                                        v-model="editProfile"
                                        :counter="20"
                                        :label="$t('profiles.attributes.name')"
                                        required
                                        color="red"
                                    ></v-text-field>
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="red darken-1" text @click="cancelCreate">
                                        {{ $t('buttons.cancel') }}
                                    </v-btn>
                                    <v-btn color="blue darken-1" text @click="save">
                                        {{ $t('buttons.save') }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'settings-profiles',
    data: () => ({
        dialogCreate: false,
        editProfile: undefined,
    }),
    created() {
        this.findProfiles();
    },
    watch: {},
    computed: {
        ...mapGetters(['profiles']),
        headers() {
            return [
                {
                    text: this.$t('profiles.attributes.name'),
                    sortable: true,
                    value: 'name',
                },
                {
                    text: this.$t('profiles.attributes.actions'),
                    sortable: false,
                    value: 'actions',
                    width: 50,
                },
            ];
        },
    },
    methods: {
        ...mapActions(['findProfiles', 'createProfile']),
        cancelCreate() {
            this.dialogCreate = false;
            this.editProfile = undefined;
        },
        save() {
            const profile = {
                name: this.editProfile,
            };

            this.createProfile(profile);
            this.dialogCreate = false;
        },
        editItem(item) {
            this.$router.push({ name: 'settings-profile', params: { profile_id: item.id } });
        },
    },
};
</script>

<style></style>
