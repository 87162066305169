var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.profiles,"sort-by":"name","items-per-page":20,"footer-props":{
                'items-per-page-options': [10, 20, 50, 100, 200],
                'show-current-page': true,
                'show-first-last-page': true,
            }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"transparent"}},[_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"800px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"rounded":"","color":"primary","dark":""}},on),[_vm._v(" "+_vm._s(_vm.$t('buttons.createProfile'))+" ")])]}}]),model:{value:(_vm.dialogCreate),callback:function ($$v) {_vm.dialogCreate=$$v},expression:"dialogCreate"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline blue--text text--darken-1"},[_vm._v(_vm._s(_vm.$t('title.newProfile')))])]),_c('v-card-text',[_c('v-text-field',{attrs:{"counter":20,"label":_vm.$t('profiles.attributes.name'),"required":"","color":"red"},model:{value:(_vm.editProfile),callback:function ($$v) {_vm.editProfile=$$v},expression:"editProfile"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":_vm.cancelCreate}},[_vm._v(" "+_vm._s(_vm.$t('buttons.cancel'))+" ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('buttons.save'))+" ")])],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }